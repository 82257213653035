@font-face {
  font-family: Biotif;
  src: url(../assets/fonts/Biotif-Regular.ttf),
    url(../assets/fonts/Biotif-Regular.woff);
}

@font-face {
  font-family: Biotif-bold;
  src: url(../assets/fonts/Biotif-Bold.ttf);
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.no-border {
  border: none !important;
}

.public-app body {
  font-family: Biotif, Arial, Helvetica, sans-serif;
  color: white;
  background-repeat: no-repeat;
}

.public-app .background {
  background-image: url(../assets/images/background.png);
  background-size: contain;
  background-position: center;
  overflow: hidden;
}

.public-app .videoLogin {
  position: fixed;
  height: 100vh;
  object-fit: cover;
  width: 100%;
}

.public-app .main {
  background: rgba(0, 0, 0, 0.82);
  height: 100vh;
}

.public-app .password-label {
  display: block;
  font-family: Biotif-bold, arial, san-serif;
  font-size: 12px;
  color: white;
  padding: 27px 0px 0px 27px;
}

.public-app input[type="password"] {
  margin: 5px 0 0 27px;
  border: none;
  color: white;
  font-size: 12px;
  background-color: transparent;
  border-bottom: solid 2px #707070;
  width: 241px;
}

.public-app input[type="password"]:focus {
  outline: none;
}

.public-app .login-btn {
  padding: 28px;
}

.public-app .login-btn button {
  background-color: #ffc65b;
  padding: 15px 105px 10px 109px;
  color: #2b2b2b;
  border-radius: 4px;
  border: none;
  font-size: 12px;
  font-weight: bold;
}

/********** LoaderScreen Page **************/
.public-app .loader {
  width: 50%;
  margin: 20% auto;
  text-align: center;
  font-size: 21px;
}

/********** Kicker Page ****************/
.public-app .navbar {
  flex-wrap: nowrap;
  display: flex;
}

.public-app .navbar-expand-lg .navbar-collapse {
  display: block !important;
}

.public-app .background-kikcer {
  background-color: #121212;
}

.public-app .form-inline {
  float: right;
  /* background-color: ; */
}

.public-app .user-btn {
  background-color: #262626;
  border-radius: 14px;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 15px;
  color: white;
  cursor: pointer;
  margin-left: 5px;
}

.public-app .user-btn:hover {
  color: white;
  text-decoration: none;
}

.public-app .btn-pic {
  padding-left: 5px;
}

.public-app .main-sec {
  padding: 100px 0px;
}

.public-app .top-row {
  flex-wrap: nowrap;
}

.public-app .top-row-center {
  flex-wrap: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.public-app .top-row-center p {
  color: whitesmoke;
}

.public-app .disclaimer {
  padding: 57px 15px 20px;
}

.public-app .disclaimer h1 {
  flex-wrap: nowrap;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: whitesmoke;
  font-size: 1rem;
  font-weight: 700;
}
.public-app .disclaimer p {
  flex-wrap: nowrap;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: whitesmoke;
  opacity: 0.5;
}

.public-app .kicker-btn {
  color: #848484;
  background-color: transparent;
  border: 1px solid #8d8d8d;
  border-radius: 18px;
  padding: 8px 25px;
  font-size: 13px;
}

.public-app .kicker-btn-filter {
  color: white;
  background-color: #595959;
  border: 1px solid #ffffff;
  border-radius: 18px;
  padding: 8px 25px;
  font-size: 13px;
}

.public-app .kicker-btn:focus {
  outline: none;
}

.public-app .kicker-btn-filter:focus {
  outline: none;
}

.public-app .popper {
  padding-top: 4%;
  z-index: 1;
  width: 200%;
  left: -48% !important;
}

.public-app .Sort-btn {
  color: #848484;
  background-color: transparent;
  border: 1px solid #8d8d8d;
  border-radius: 18px;
  padding: 8px 30px;
  font-size: 13px;
}

.public-app .Sort-btn:focus {
  outline: none;
}

.public-app .results {
  text-align: right;
  font-size: 14px;
  margin-top: 10px;
  font-family: Biotif, Arial, Helvetica, sans-serif;
  color: #838383;
}

.public-app .cards-row {
  padding: 0px 0;
}

.public-app .card {
  background-color: transparent;
  border: none;
  width: 100% !important;
  margin: 30px 0;
}

.public-app .card-img-top {
  position: relative;
  height: 185px;
  object-fit: cover;
  border-radius: 5px;
}

.public-app .card-title {
  font-size: 16px;
  color: white;
  margin-bottom: 7px;
}

.public-app .card-body {
  padding: 19px 0 0 0;
  padding-left: 0px !important;
}

.public-app .card-text {
  font-size: 12px;
  color: #a2a2a2;
  max-lines: 2;
  display: -webkit-box;
  max-width: 400px;
  min-height: 32px;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.public-app .card-body-bottom {
  color: #ffc65b;
  font-size: 10px;
}

.public-app .card-link {
  color: #ffc65b;
  font-size: 10px;
  letter-spacing: 0.64px;
}

.public-app .card-link:hover {
  color: #ffc65b;
}

.public-app .card-link + .card-link {
  margin-left: 0.25rem;
}

.public-app .text-overcard {
  letter-spacing: 0.4px;
  color: #2b2b2b;
  background-color: #ffc65b;
  border-radius: 2px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 5px;
  font-size: 8px;
  font-weight: bold;
  position: absolute;
  top: 7px;
  left: 7px;
}

.public-app .bottom-icon {
  position: absolute;
  right: 25px;
  bottom: 100px;
  background-color: #434343;
  padding: 1.5px;
}
.public-app .bottom-icon-otherP {
  position: absolute;
  right: 10px;
  bottom: 100px;
  background-color: #434343;
  padding: 1.5px;
}

.public-app .bottom-pdf-icon {
  position: absolute;
  right: 60px;
  bottom: 100px;
  background-color: #434343;
  padding: 3.5px;
}

/*********** Profile Page CSS ************/
.public-app .profile-navbar {
  position: absolute;
  right: 0;
  top: 12px;
}

.public-app .profile-container {
  display: flex !important;
}

.public-app .profile-sec {
  top: -42px;
}

.public-app .body-measurements-row {
  display: contents;
}

.public-app .username-container {
  background-color: #121212;
}

.public-app .star-icon {
  background-color: #434343;
  border-radius: 4px;
  padding: 0px;
}

.public-app .des-icon {
  background-color: #434343;
  border-radius: 4px;
  padding: 5px;
}

.public-app .user-name h2 {
  font-family: Biotif, Arial, Helvetica, sans-serif;
  font-size: 25px;
  font-weight: bold;
  padding: 15px 22px;
  color: #ffffff;
}

.public-app .user-name p {
  letter-spacing: 0.64px;
  color: #ffc65b;
  text-transform: uppercase;
  font-size: 8px;
  padding: 0 0 15px 22px;
  font-family: Biotif, Arial, Helvetica, sans-serif;
}

.public-app .height {
  color: #ffffff;
  font-size: 16px;
  margin-top: 6px !important;
}

.public-app .he {
  color: #bcbcbc;
  font-size: 12px;
}

.public-app .graph-container {
  overflow: auto;
}

.public-app .bio-container {
  background-color: rgb(255, 255, 255, 0.04);
  border-bottom: none;
}

.public-app .bio-container .container {
  padding-left: 0;
  padding-right: 0;
}

.p-section {
  min-height: 300px;
  padding-top: 100px;
  border-bottom: 1px solid gray;
}

.public-app .p-section-heading {
  font-size: 25px;
  padding-right: 22px;
  font-weight: bold;
  color: white;
  font-family: Biotif, Arial, Helvetica, sans-serif;
}

.public-app .p-section-subheading {
  font-size: 16px;
  padding-right: 22px;
  color: gray;
  font-family: Biotif, Arial, Helvetica, sans-serif;
}

.public-app .parentDisable {
  position: fixed;
  top: 0;
  left: 0;
  background: #121212;
  opacity: 1;
  z-index: 998;
  height: 100%;
  width: 100%;
}

.public-app .overlay-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #121212;
  background: #121212;
  opacity: 1;
  z-index: 1000;
}

.public-app .p-section-des {
  color: #ffffff;
  font-size: 16px;
}

.public-app .p-section-des-heading {
  font-size: 16px;
  color: white;
  font-weight: bold;
  margin-top: 0;
}

.public-app .p-section-des-details {
  color: #969696;
  font-size: 16px;
  margin-top: 0;
}

.public-app .other-cards {
  padding: 0 21px 15%;
}

/*********** Saved Players CSS ************/
.public-app .main-sec-savedPlayers {
  padding-top: 50px;
}

.public-app .SavedPlayers-heading {
  font-size: 20px;
  color: white;
  font-family: Biotif, Arial, Helvetica, sans-serif;
}

/* React Spinner CSS */
.public-app .parentDisable {
  position: fixed;
  top: 0;
  left: 0;
  background: #121212;
  opacity: 0.8;
  z-index: 998;
  height: 100%;
  width: 100%;
}

.public-app .overlay-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #121212;
  background: #121212;
  opacity: 0.8;
  z-index: 1000;
}

/*********** Position Screen CSS ************/
.public-app .positions {
  position: relative;
}

.public-app .main-sec-position {
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.public-app .layout-svg {
  width: 100%;
  height: 100%;
}

.public-app .agent-sec {
  border-top: 1px solid #707070;
  padding: 16px 0;
}

.public-app .agent-name {
  color: #ffffff;
  font-size: 10px;
  margin: 0px;
}

.public-app .agent-email {
  color: #ffffff;
  margin: 0px;
  font-size: 10px;
  opacity: 0.51;
}

.public-app .agent-container {
  padding: 60px 0;
  max-width: 935px;
  margin: 0 auto;
  width: 100%;
}

.shirt-img {
  max-height: 100%;
  max-width: 100%;
}

.public-app .position1 {
  position: absolute;
  bottom: 4%;
  left: 16%;
}

.public-app .position2 {
  position: absolute;
  bottom: 40%;
  left: 16%;
}

.public-app .position3 {
  position: absolute;
  top: 11%;
  left: 16%;
}

.public-app .position4 {
  position: absolute;
  bottom: 27%;
  left: 30%;
}

.public-app .position5 {
  position: absolute;
  bottom: 57%;
  left: 30%;
}

.public-app .position6 {
  position: absolute;
  bottom: 12%;
  left: 47.5%;
}

.public-app .position7 {
  position: absolute;
  bottom: 40%;
  left: 47.5%;
}

.public-app .position8 {
  position: absolute;
  top: 16.5%;
  left: 47.5%;
}

.public-app .position9 {
  position: absolute;
  bottom: 23%;
  right: 36%;
}

.public-app .position10 {
  position: absolute;
  bottom: 57%;
  right: 36%;
}

.public-app .position11 {
  position: absolute;
  bottom: 12%;
  right: 8%;
}

.public-app .position12 {
  position: absolute;
  bottom: 29%;
  right: 21.5%;
}

.public-app .position13 {
  position: absolute;
  bottom: 48%;
  right: 21.5%;
}

.public-app .position14 {
  position: absolute;
  top: 17%;
  right: 8%;
}

.public-app .position15 {
  position: absolute;
  bottom: 40%;
  right: 8%;
}

.public-app .shirt-number {
  position: relative;
  background: transparent;
  border: none;
  color: rgb(107, 107, 107) !important;
  bottom: 34px;
  left: 20px;
}

.public-app .position10 p {
  left: 16px;
}

.public-app .position12 p {
  left: 16px;
}

.public-app .position13 p {
  left: 16px;
}

.public-app .position14 p {
  left: 16px;
}

.public-app .position15 p {
  left: 16px;
}

.public-app .shirt-number:focus {
  outline: none;
}

.public-app .profile-menu {
  background-color: #262626;
  padding-left: 5px;
  border-radius: 14px;
  position: absolute;
  right: 1.5%;
  top: 2%;
  z-index: 998;
}

.public-app .shirt-number:hover {
  outline: none;
  text-decoration: none;
  color: whitesmoke;
}

.public-app .profileMenu {
  background: #262626 !important;
  padding: 1px 0px 1px 17px;
  border-radius: 14px !important;
  position: absolute;
  right: 1.5%;
  top: 2%;
}

.public-app .dropDown {
  color: white;
  font-size: 14px;
  padding: 0 16px;
}

.public-app .dropDown:hover {
  background: #595959 !important;
}

.public-app .profileMeasure {
  display: flex;
  justify-content: space-between;
}

.public-app .playerSPec {
  flex-direction: column !important;
  padding: 7% 1% !important;
}

.public-app .playerSPec img {
  height: 29px !important;
}

.public-app .agentHeading {
  padding: 0 15px;
  font-size: 15px;
  font-weight: normal;
  color: #fff;
}

.public-app .agentSpacing {
  width: 100%;
  padding: 0px !important;
}

.public-app .headerMenu {
  color: white !important;
  font-size: 12px !important;
}

.public-app .headerMenu:hover {
  background-color: #393939 !important;
}

.public-app .afterLogin {
  animation: fadein 5s !important;
  -moz-animation: fadein 5s !important; /* Firefox */
  -webkit-animation: fadein 5s !important; /* Safari and Chrome */
  -o-animation: fadein 5s !important; /* Opera */
}

.public-app .menu-itemM {
  position: absolute !important;
  padding-top: 1% !important;
  z-index: 1 !important;
}

.public-app .sort-menuM {
  position: absolute !important;
  padding-top: 1% !important;
  z-index: 1 !important;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*********** Media Query  CSS ************/
@media (min-width: 992px) and (max-width: 1199px) {
  .public-app .main-sec {
    padding: 15px;
  }

  .public-app .kicker-btn-filter {
    color: white;
    background-color: #595959;
    border: 1px solid #ffffff;
    border-radius: 18px;
    padding: 8px 11px;
    font-size: 13px;
  }
}

/* Media Querey for positions layout */
@media (min-width: 768px) and (max-width: 1150px) {
  .shirt-img {
    max-height: 70%;
    max-width: 70%;
  }

  .public-app .shirt-number {
    font-size: 10px;
    bottom: 25px;
    left: 14px;
  }

  .public-app .position10 p {
    left: 12px;
  }

  .public-app .position12 p {
    left: 12px;
  }

  .public-app .position13 p {
    left: 12px;
  }

  .public-app .position14 p {
    left: 12px;
  }

  .public-app .position15 p {
    left: 12px;
  }
}

@media (max-width: 767px) {
  .public-app .positions {
    margin-top: 30%;
    transform: rotate(90deg) !important;
  }
}

/******************** MEDIA QUERY FOR THE FIELD POSITION PLAYERS PAGE *******************/
@media (min-width: 767px) and (max-width: 991px) {
  .public-app .main-sec {
    padding: 15px;
  }

  .public-app .bottom-icon {
    position: absolute;
    right: 23px;
    bottom: 110px;
    background-color: #434343;
    padding: 0.5px;
  }

  .public-app .card-img-top {
    position: relative;
    height: 130px;
    object-fit: cover;
  }

  .public-app .bottom-pdf-icon {
    position: absolute;
    right: 56px;
    bottom: 110px;
    background-color: #434343;
    padding: 3.5px;
  }

  .public-app .bottom-icon-otherP {
    position: absolute;
    right: 10px;
    bottom: 100px;
    background-color: #434343;
    padding: 4.5px;
  }

  .public-app .main-sec-position {
    padding: 60px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
}

@media (min-width: 540px) and (max-width: 767px) {
  .positionRM {
    transform: rotate(270deg);
  }

  .shirt-img {
    max-height: 100%;
    max-width: 60%;
  }

  .public-app .shirt-number {
    position: relative;
    background: transparent;
    border: none;
    color: whitesmoke;
    bottom: 19px;
    left: 12px;
    font-size: 11px;
  }

  .public-app .position10 p {
    left: 9px;
  }

  .public-app .position12 p {
    left: 9px;
  }

  .public-app .position13 p {
    left: 9px;
  }

  .public-app .position14 p {
    left: 9px;
  }

  .public-app .position15 p {
    left: 9px;
  }

  .public-app .position1 {
    position: absolute;
    bottom: 7%;
    left: 16%;
  }

  .public-app .position2 {
    position: absolute;
    bottom: 42%;
    left: 16%;
  }

  .public-app .position3 {
    position: absolute;
    top: -1%;
    left: 16%;
  }

  .public-app .position7 {
    position: absolute;
    bottom: 42%;
    left: 47.5%;
  }

  .public-app .position8 {
    position: absolute;
    top: 4.5%;
    left: 47.5%;
  }

  .public-app .position9 {
    position: absolute;
    bottom: 23%;
    right: 33%;
  }

  .public-app .position9 {
    position: absolute;
    bottom: 23%;
    right: 31%;
  }

  .public-app .position10 {
    position: absolute;
    bottom: 60%;
    right: 31%;
  }

  .public-app .position12 {
    position: absolute;
    bottom: 29%;
    right: 21.5%;
  }

  .public-app .position13 {
    position: absolute;
    bottom: 50%;
    right: 21.5%;
  }

  .public-app .position14 {
    position: absolute;
    top: 5%;
    right: 8%;
  }

  .public-app .position15 {
    position: absolute;
    bottom: 42%;
    right: 8%;
  }
}

@media (min-width: 400px) and (max-width: 539px) {
  .positionRM {
    transform: rotate(270deg);
  }

  .public-app .top-row-center {
    width: 100%;
  }

  .shirt-img {
    max-height: 100%;
    max-width: 50%;
  }

  .public-app .shirt-number {
    position: relative;
    background: transparent;
    border: none;
    color: whitesmoke;
    bottom: 17px;
    left: 10px;
    font-size: 11px;
  }

  .public-app .position1 {
    position: absolute;
    bottom: 10%;
    left: 16%;
  }

  .public-app .position2 {
    position: absolute;
    bottom: 45%;
    left: 16%;
  }

  .public-app .position3 {
    position: absolute;
    top: 1%;
    left: 16%;
  }

  .public-app .position5 {
    position: absolute;
    bottom: 63%;
    left: 30%;
  }

  .public-app .position7 {
    position: absolute;
    bottom: 45%;
    left: 47.5%;
  }

  .public-app .position8 {
    position: absolute;
    top: 4.5%;
    left: 47.5%;
  }

  .public-app .position9 {
    position: absolute;
    bottom: 25%;
    right: 30%;
  }

  .public-app .position10 {
    position: absolute;
    bottom: 63%;
    right: 30%;
  }

  .public-app .position13 {
    position: absolute;
    bottom: 55%;
    right: 21.5%;
  }

  .public-app .position14 {
    position: absolute;
    top: 4.5%;
    right: 8%;
  }

  .public-app .position15 {
    position: absolute;
    bottom: 45%;
    right: 8%;
  }

  .public-app .position10 p {
    left: 6px;
  }

  .public-app .position12 p {
    left: 6px;
  }

  .public-app .position13 p {
    left: 6px;
  }

  .public-app .position14 p {
    left: 6px;
  }

  .public-app .position15 p {
    left: 6px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .public-app .main-sec {
    padding: 0px;
  }

  .public-app .profile-container {
    display: block !important;
  }

  .public-app .body-measurements-row {
    display: flex;
  }

  .p-section {
    padding-top: 10px;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .public-app .p-section-heading {
    font-size: 25px;
    padding: 0;
    text-align: left;
    font-weight: bold;
    font-family: Biotif, Arial, Helvetica, sans-serif;
    letter-spacing: -2px;
  }

  .public-app .p-section-des {
    color: #ffffff;
    font-size: 16px;
    padding-top: 10px;
  }

  .public-app .user-name {
    display: flex;
    justify-content: center;
  }

  .public-app .profile-menu {
    right: 5.5% !important;
  }
}

@media (max-width: 576px) {
  .disclaimer {
    padding-left: 5%;
    padding-right: 5%;
  }

  .disclaimer p {
    font-size: 0.8rem !important;
  }

  .disclaimer h1 {
    font-size: 0.8rem !important;
  }
}

/******************** END MEDIA QUERY FOR THE FIELD POSITION PLAYERS PAGE *******************/

@media (min-width: 600px) and (max-width: 767px) {
  .public-app .agent-container {
    padding: 60px 0;
    max-width: 935px;
    margin: 20% auto;
    width: 100%;
  }
}
@media (min-width: 320px) and (max-width: 600px) {
  .public-app .height {
    color: #ffffff;
    font-size: 14px;
    margin-top: 6px !important;
  }

  .public-app .card-img-top {
    position: relative;
    object-fit: cover;
    height: auto;
  }

  .public-app .agent-container {
    padding: 60px 0;
    max-width: 935px;
    margin: 18% auto;
    width: 100%;
  }

  .public-app .profileDiv {
    justify-content: flex-start !important;
  }

  .public-app .star-icon {
    margin-left: 10px;
  }

  .public-app .des-icon {
    margin-left: 10px;
  }

  .public-app .user-name {
    display: flex;
    justify-content: flex-start;
  }

  .public-app .user-name h2 {
    font-family: Biotif, Arial, Helvetica, sans-serif;
    font-size: 25px;
    font-weight: bold;
    padding: 15px 22px 15px 0;
    color: #ffffff;
  }

  .public-app .user-name p {
    letter-spacing: 0.64px;
    color: #ffc65b;
    text-transform: uppercase;
    font-size: 8px;
    padding: 0 0 15px 0px;
    font-family: Biotif, Arial, Helvetica, sans-serif;
  }

  .public-app .playerSPec {
    flex-direction: column !important;
    width: 33.33%;
    padding: 7% 1% !important;
    border-bottom: 1px solid #262626;
  }

  .public-app .playerSPec7 {
    border-bottom: none;
  }

  .p-section {
    padding-top: 10px;
  }

  .public-app .p-section-heading {
    padding: 0;
    margin: 20px 0;
  }

  .public-app .other-cards {
    padding: 0 0px 15%;
  }

  .public-app .card-text {
    margin: 0;
  }
}

/****************** NewMediaQuery For Larger Pitch For Mobile***********************/
@media (min-width: 320px) and (max-width: 500px) {
  .positionRM {
    transform: rotate(270deg);
  }

  .public-app .positions {
    margin-top: 20%;
    transform: rotate(90deg) !important;
  }

  .public-app .layout-svg {
    width: 145%;
    height: 100%;
  }

  .shirt-img {
    max-height: 100%;
    max-width: 65%;
  }

  .public-app .position1 {
    position: absolute;
    bottom: 9%;
    left: 26%;
  }

  .public-app .shirt-number {
    position: relative;
    background: transparent;
    border: none;
    color: whitesmoke;
    bottom: 20px;
    left: 12px;
    font-size: 11px;
  }

  .public-app .position2 {
    position: absolute;
    bottom: 42%;
    left: 26%;
  }

  .public-app .position3 {
    position: absolute;
    top: -3%;
    left: 26%;
  }

  .public-app .position4 {
    position: absolute;
    bottom: 27%;
    left: 45%;
  }

  .public-app .position5 {
    position: absolute;
    bottom: 61%;
    left: 45%;
  }

  .public-app .position6 {
    position: absolute;
    bottom: 12%;
    left: 68.5%;
  }

  .public-app .position7 {
    position: absolute;
    bottom: 42%;
    left: 68.5%;
  }

  .public-app .position8 {
    position: absolute;
    top: 2.5%;
    left: 68.5%;
  }

  .public-app .position9 {
    position: absolute;
    bottom: 23%;
    right: 6%;
  }

  .public-app .position10 {
    position: absolute;
    bottom: 63%;
    right: 6%;
  }

  .public-app .position10 p {
    left: 10px;
  }

  .public-app .position11 {
    position: absolute;
    bottom: 12%;
    right: -29%;
  }

  .public-app .position12 {
    position: absolute;
    bottom: 29%;
    right: -8.5%;
  }

  .public-app .position12 p {
    left: 10px;
  }

  .public-app .position13 {
    position: absolute;
    bottom: 53%;
    right: -8.5%;
  }

  .public-app .position13 p {
    left: 10px;
  }

  .public-app .position14 {
    position: absolute;
    top: 1%;
    right: -29%;
  }

  .public-app .position14 p {
    left: 10px;
  }

  .public-app .position15 {
    position: absolute;
    bottom: 42%;
    right: -29%;
  }

  .public-app .position15 p {
    left: 10px;
  }

  .public-app .agent-container {
    padding: 35% 0 0;
    max-width: 935px;
    margin: 18% auto;
    width: 100%;
  }
}
