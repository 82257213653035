@font-face {
  font-family: Biotif;
  src: url(../assets/fonts/Biotif-Regular.ttf),
    url(../assets/fonts/Biotif-Regular.woff);
}

@font-face {
  font-family: Biotif-bold;
  src: url(../assets/fonts/Biotif-Bold.ttf);
}

.container-main {
  margin-top: 0;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-color: lightgray;
  box-shadow: none;
  outline: 0;
  justify-content: center;
  height: 100vh !important;
}

.container-main input {
  color: #ffffff;
  background: transparent;
  width: 98%;
  margin-top: 7%;
  border: none;
  border-bottom: 1px solid #ada6a6;
}

.container-main input:focus {
  outline: transparent;
  border-bottom: 1px solid white;
}

.container-main input:hover {
  outline: transparent;
  border-bottom: 1px solid white;
}

.login-button {
  background: #ffc65b;
  width: 100%;
  border: none;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 3px;
}

.heading {
  font-size: 100%;
  color: #ffffff;
  font-family: Biotif-Regular;
}

.text {
  font-size: 100%;
  color: #ffffff;
  font-family: Biotif-Regular;
}

.textInput {
  font-family: Biotif-Regular;
}

.admin-area {
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.admin-area p {
  font-size: 10px !important;
}

.login-form {
  width: 30%;
}

.MuiGrid-spacing-xs-4 {
  width: 100% !important;
  padding: 10px 25px;
}

.loginHeading {
  font-size: 14px !important;
  font-family: Biotif-Regular;
  opacity: 0.46;
}

.logoutButton {
  font-size: 14px !important;
  font-family: "Biotif-Regular" !important;
  font-weight: bold !important;
  text-transform: none !important;
}

@media (min-width: 320px) and (max-width: 600px) {
  .container-main {
    margin-top: 0;
    padding-top: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    border-color: lightgray;
    box-shadow: none;
    outline: 0;
    justify-content: center;
    height: 100vh !important;
  }

  .login-form {
    width: 70%;
  }

  .loginHeading {
    font-size: 12px !important;
    font-family: Biotif-Regular;
    opacity: 0.46;
  }

  .login-area {
    margin: 0 !important;
  }
}
@media (min-width: 600px) and (max-width: 767px) {
  .container-main {
    margin-top: 0;
    padding-top: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    border-color: lightgray;
    box-shadow: none;
    outline: 0;
    justify-content: center;
    height: 100vh !important;
  }

  .login-form {
    width: 50%;
  }
}
