@font-face {
  font-family: "Biotif-Regular";
  src: url("../fonts/Biotif-Regular.eot"); /* IE9 Compat Modes */
  src: url("../fonts/Biotif-Regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/Biotif-Regular.otf") format("opentype"),
    /* Open Type Font */ url("../fonts/Biotif-Regular.svg") format("svg"),
    /* Legacy iOS */ url("../fonts/Biotif-Regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/Biotif-Regular.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/Biotif-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: "Arial";
  src: url("../fonts/ARIAL.eot"); /* IE9 Compat Modes */
  src: url("../fonts/ARIAL.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/Biotif-Regular.otf") format("opentype"),
    /* Open Type Font */ url("../fonts/ARIAL.svg") format("svg"),
    /* Legacy iOS */ url("../fonts/ARIAL.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/ARIAL.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/ARIAL.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Biotif-Regular" !important;
  box-shadow: none !important;
}

.tab-container {
  margin-top: 4em;
  padding: 6%;
  width: 80%;
  background: transparent;
  display: block;
  z-index: 2;
}

.tab {
  padding: 2.3% 0;
  border-bottom: 0.5px solid #707070;
}

.tab .nav-link:focus {
  outline: none;
}

.tab .nav-link {
  padding: 1em 3em 0 0;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
  opacity: 0.5;
  border-bottom: none;
  text-decoration: none;
}

.tab a.nav-link.active {
  color: #ffffff;
  opacity: 1;
}

.sidebar {
  margin-top: 0em;
  z-index: 2;
  top: 0;
  left: 0;
  padding: 2% 2% 2% 3%;
  width: 12%;
  height: 100%;
  background: #2b2b2b;
  position: fixed;
  display: block;
}

.sidebar .nav-link {
  font-family: Biotif-Regular;
  cursor: pointer;
  font-size: 14px;
  color: #b7b7b7;
  border-bottom: none;
  text-decoration: none;
}

.sidebar a.nav-link.active {
  color: #ffffff;
}

.full-input {
  width: 100%;
  display: inline-block;
  padding: 5px 8px;
  height: 50pt !important;
}

.no-label-input {
  width: 100%;
  display: inline-block;
  padding: 5px 8px;
  height: 30pt !important;
  margin-left: 0 !important;
}

.no-label-input input {
  height: 100%;
  width: 95%;
  padding: 0;
}

.full-select {
  height: 100%;
  margin-top: 0 !important;
}

.full-input-border {
  border: 1pt solid #393939;
  border-radius: 6pt;
}

.searchBar {
  width: 250%;
  font-family: Arial, Helvetica, sans-serif;
}

.input {
  width: 100%;
  outline: none;
  border: none;
  padding-top: 8px;
  font-size: 12px;
  resize: none;
  overflow: hidden;
  background-color: transparent;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}
.inputS {
  width: 35%;
  outline: none;
  border: none;
  padding-top: 8px;
  font-size: 12px;
  overflow: hidden;
  background-color: transparent;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

.full-input-bio {
  width: 100%;
  margin-top: 19px !important;
  display: inline-block;
  padding: 5px 10px;
  height: 75pt !important;
  font-family: Arial, Helvetica, sans-serif;
}
.efull-input-bio {
  width: 100%;
  margin-top: 0px !important;
  display: inline-block;
  padding: 5px 10px;
  height: 75pt !important;
  font-family: Arial, Helvetica, sans-serif;
}
.input::placeholder {
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(207, 207, 207);
}

label.floating-label {
  padding-top: 10px;
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  /* letter-spacing: 1px; */
  font-family: Arial;
  text-transform: uppercase;
}
.send-button {
  background: #ffc65b;
  width: 35%;
  border: none;
  margin-top: 35px;
  padding: 21px 32px 14px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 3px;
  font-weight: bold;
  font-size: 10px;
  font-family: Biotif-Regular;
}
.add-button {
  background: #ffc65b;
  width: 34%;
  border: none;
  /* margin-top: 10vh; */
  padding: 16px 32px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 3px;
  font-weight: bold;
  font-size: 10px;
  font-family: Biotif-Regular;
}
.ceateteam-btn {
  background: #ffc65b;
  width: 91%;
  border: none;
  /* margin-top: 10vh; */
  padding: 16px 32px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 3px;
  font-weight: bold;
  font-size: 10px;
  font-family: Biotif-Regular;
}

.full-input-select {
  width: 100%;
  display: inline-block;
  padding: 5px 8px;
  font-family: Arial, Helvetica, sans-serif;
  height: 40pt !important;
  border-radius: 0px !important;
  border-left: solid 1px #393939;
}

.accText {
  font-size: 12px !important ;
  color: #ffffff;
  font-family: Biotif-Regular !important;
}

.custom-select {
  position: relative;
  background: transparent;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  color: white;
  width: 80% !important;
  margin-top: 10px;
}

.custom-select option {
  background: #2b2b2b;
  height: 20pt !important;
}

.custom-select:focus {
  border: none;
  outline: none;
}

.unit-span {
  color: #ffffff;
  font-size: 12px;
}
.parentDisable {
  position: fixed;
  top: 0;
  left: 0;
  background: #121212;
  opacity: 1;
  z-index: 998;
  height: 100%;
  width: 100%;
}

.overlay-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #121212;
  background: #121212;
  opacity: 1;
  z-index: 1000;
}

.table {
  min-width: 80%;
  font-family: "Biotif-Regular" !important;
}
.paper-table {
  width: "100%";
  height: "70%";
  padding: 2%;
  background-color: "#707070";
}
.fab {
  width: 20;
  height: 20;
  background-color: blue;
}
.expandIcon {
  transform: "rotate(0deg)";
}
.collapseIcon {
  transform: "rotate(180deg)";
}

.date-range-picker {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* Furqan Code */
.TeamAccordion-root-1 {
  border-bottom: solid 1px white;
}
.checkBox-align {
  display: flex;
  justify-content: flex-end;
}
.selectAll-row {
  text-align: right !important;
}
#panel1a-header {
  padding: 0 !important;
}
.MuiAccordionDetails-root-202 {
  display: flex;
  padding: 8px 0 !important;
}
.borderLeft-div {
  padding: 0px 16px 9px 16px;
  border-left: solid 1px #393939;
}
.borderLeft-Kpis {
  padding: 0px 10px 0px;
  border-left: solid 1px #393939;
}
.KPIS-calender {
  padding: 0 12px !important;
  color: #fff !important;
}
.TeamAccordion-heading-2 {
  font-size: 14px !important;
  font-family: Biotif-Regular;
}
.selectHeading {
  font-size: 12px !important;
  font-family: Biotif-Regular;
  margin-left: 20px;
}

.MuiTableCell-sizeSmall:last-child {
  font-family: "Biotif-Regular" !important;
}
.MuiTableHead-root {
  border-bottom: 0.5px solid #707070 !important;
}
.MuiGrid-spacing-xs-3 > .MuiGrid-item {
  padding: 12px 12px 0 0 !important;
}
.positonRow {
  display: flex !important;
  justify-content: space-between;
}
.anotherPosition-div {
  /* padding: 11px 0 11px 87px; */
}
.search-btn .MuiAutocomplete-option {
  border-bottom: 1px solid #393939 !important;
}
.hiddenPlayer-div {
  justify-content: flex-end !important;
}
.PrivateSwitchBase-root-6 {
  padding: 9px 0 !important;
}
.MuiTableCell-body-48 {
  font-family: "Biotif-Regular" !important;
}
.MuiTableCell-root-46 {
  padding: 16px 0 !important;
}

.MuiPaper-elevation1-15 {
  box-shadow: none !important;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.MuiTableCell-root {
  padding: 16px 0 !important;
}
.MuiTableCell-body {
  font-family: "Biotif-Regular" !important;
}
.removeBtn {
  font-size: 11px !important;
  padding: 6px 0 !important;
  font-weight: bold !important;
}
.MuiTableCell-body-136 {
  font-family: "Biotif-Regular" !important;
}
.teamRow {
  border-bottom: solid 1px #707070;
}
th {
  font-family: "Biotif-Regular" !important;
  padding: 16px 0 !important;
}
td {
  font-family: "Biotif-Regular" !important;
  font-size: 12px !important;
}
.sesionCol {
  padding: 30px 0 0 0 !important;
}
.MuiInput-underline-7932:not(.Mui-focused) {
  border-bottom: none;
}
.MuiInput-underline-7932:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: none !important;
  pointer-events: none;
}
.MuiInput-underline-7932:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-bottom: none !important;
  pointer-events: none;
}
.MuiInput-underline:not(.Mui-focused) {
  border-bottom: none !important;
}
.search-btn {
  background: transparent !important;
  margin: 5px 0 10px 10px !important;
  color: #fff;
}
.Index-searchBar-1 {
  width: 234% !important;
  background-color: transparent;
}
.MuiTextField-root-3612 {
  background: transparent !important;
}
.MuiTableHead-root-296 {
  display: table-header-group;
  border-bottom: 1px solid #707070 !important;
}
.teamsGrid {
  margin-top: 20px !important;
}
.contactportionCL {
  margin-top: -70px !important;
}
.teamsGrid::-webkit-scrollbar {
  width: 0px;
}
.teamsGrid::-webkit-scrollbar * {
  background: transparent;
}
.teamsGrid::-webkit-scrollbar-thumb {
  background: transparent !important;
}
.search-btn label {
  text-transform: uppercase !important;
  font-family: Arial;
  font-size: 12px !important;
  font-weight: bold !important;
}
.MuiFab-root-454 {
  color: rgba(0, 0, 0, 0.87);
  width: 45px !important;
  height: 45px !important;
  padding: 0;
  /* font-size: 12px !important; */
  min-width: 0;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  min-height: 36px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 50%;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  background-color: #ffffff !important;
}
.teamsTable th {
  font-size: 12px !important;
}
.checkbox-select {
  padding: 0px 0px 3px !important;
}
.checkbox-select span svg {
  font-size: 1.2rem;
}
.borderRadius-left {
  border-radius: 4px 0 0 4px !important;
}
.borderRadius-right {
  border-radius: 0 4px 4px 0px !important;
}
.videoCursor {
  cursor: pointer;
}
.edit-buttons {
  background: #ffc65b;
  border: none;
  /* margin-top: 10vh; */
  padding: 12px 28px 9px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 3px;
  font-size: 15px;
  font-family: Biotif-Regular;
}

.modal-overlay {
  opacity: 0.59;
  position: fixed;
  top: 0;
  left: 0;
  background: #000000;
  z-index: 998;
  height: 100%;
  width: 100%;
}

.mdl-overlay {
  opacity: 1;
}

.modal-link {
  max-width: 301px;
  max-height: 197px;
  width: 301px;
  height: 197px;
  background: #333333;
  padding: 30px;
  opacity: 1;
  margin: 0;
  z-index: 1000;
  border: none;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
}

.modal-buttons {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: space-between;
}

.modal-text {
  color: #fff;
  font-size: 20px;
  text-align: center;
}

.modal-body {
  width: 100%;
  padding: 15px 0px;
  position: relative;
  align-content: center;
  height: 197px;
}

.modal-btn {
  background: #ffc65b;
  width: 100%;
  border: none;
  padding: 21px 32px 14px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 3px;
  font-weight: bold;
  font-size: 10px;
  font-family: Biotif-Regular;
  position: absolute;
  bottom: 10px;
}

.modal-btn-tabs {
  background: #ffc65b;
  width: 40%;
  border: none;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 3px;
  font-weight: bold;
  font-size: 10px;
  font-family: Biotif-Regular;
  margin: 20;
}
.okBtn {
  position: absolute;
  bottom: 10px;
}
.cancelBtn {
  position: absolute;
  bottom: 10px;
  right: 0;
  color: #fff;
}

.alignEdit-Btns {
  display: flex;
  justify-content: center;
}
.alignRemove-Btns {
  display: flex;
  justify-content: flex-end;
}
.editPadding {
  padding-top: 0 !important;
}
.editSpacing {
  margin-top: 30px;
  padding: 3px 0px;
}
.editHeadingSpace {
  padding: 0px 12px;
}
.MuiAutocomplete-option-533 {
  border-bottom: solid 1px #393939;
}
.MuiAutocomplete-listbox-530 {
  padding: 8px 6px !important;
}
.editSearch label {
  color: #fff !important;
  font-family: Biotif-Regular !important;
  font-size: 12px !important;
  font-weight: bold !important;
}
.pointsHeading {
  font-size: 11px !important;
  font-family: Biotif-Regular !important;
}

.accText-creatlink {
  font-size: 12px !important ;
  color: #ffffff;
  padding-top: 4px !important;
  padding-right: 6px;
  font-family: Biotif-Regular !important;
}
.teamPlayers {
  font-size: 14px;
}
.sesionCol {
  padding: 20px 0 !important;
}
.alertHeading {
  color: #767676;
  margin-top: 0;
  font-size: 12px;
}
.addicon {
  margin-left: 0px;
}
.full-input-player {
  background-color: rgb(255, 255, 255, 0.26) !important;
  padding: 2px 6px 2px 12px;
  margin: 5px 0px 20px;
  width: 85%;
}
.full-input-team {
  background-color: rgb(255, 255, 255, 0.26) !important;
  padding: 2px 6px 2px 12px;
  margin: 5px 0px 20px;
  width: 96%;
}
.efull-input-player {
  background-color: rgb(255, 255, 255, 0.26) !important;
  padding: 2px 6px 2px 12px;
  margin: 5px 0px 15px;
  width: 94%;
}
.full-input-position {
  background-color: rgb(255, 255, 255, 0.26) !important;
  padding: 2px 6px 2px 12px;
  margin: 5px 0px 20px;
  width: 83%;
}
.efull-input-position {
  background-color: rgb(255, 255, 255, 0.26) !important;
  padding: 2px 6px 2px 12px !important;
  margin: 0;
  width: 94%;
}
.full-input-Preteam {
  background-color: rgb(255, 255, 255, 0.26) !important;
  padding: 2px 2px 2px 12px;
  margin: 5px 0px 20px;
  width: 97%;
}

.full-input-appearance {
  background-color: rgb(255, 255, 255, 0.26) !important;
  padding: 2px 2px 2px 12px;
  margin-top: 15px;
  margin-left: 20px;
  margin-bottom: 28px;
  width: 80%;
}
.add-plus {
  width: 26px;
  margin-top: 3px;
  cursor: pointer;
}

.remove-plus {
  width: 26px;
  margin-top: 3px;
  cursor: pointer;
  transform: rotate(45deg);
}
.stats-label {
  padding-top: 10px !important;
  text-transform: none !important;
  font-weight: normal !important;
}
.addPlayer {
  margin: 15px 0 67px 0 !important;
}
.videoPlayer {
  margin: 15px 0 67px 0 !important;
  position: relative;
}

.tickVideoPlayer {
  position: absolute;
  right: 4px;
  top: 4px;
}

.editPlayer {
  margin: 0px !important;
  padding-top: 8px !important;
}
.table-editPlayer {
  font-size: 12px !important;
  font-family: Biotif-Regular;
}
.logo-img {
  /* width: 55%;
  height: 7%; */
  width: 170px;
  margin-left: -18px;
}
.searchCreatlink {
  padding: 5;
  width: 70%;
  background: rgb(255, 255, 255, 0.26) !important;
}
.statSpacing {
  background: rgb(255, 255, 255, 0.26) !important;
  margin: 15px 0 15px 0;
}
.addbtn-Grid {
  margin-top: 20px;
  text-align: left;
}
textarea {
  width: 100% !important;
}
.crossPre {
  padding-left: 30px;
}
.crossPreAlert {
  margin-left: 2rem !important;
}
.searchEdit {
  width: 62%;
}
.searchEdit input {
  color: #fff !important;
}
.update-btn {
  width: 94%;
  background: #4ecc16;
  color: #ffffff;
}
.remove-btn {
  width: 85%;
  background: #ff5b5b;
  color: #ffffff;
}
.hide-btn {
  width: 99%;
  color: #ffffff;
}
.show-btn {
  background: #707070;
  width: 99%;
  color: #ffffff;
}
.search-btn ::before {
  border-bottom: none !important;
}
.search-btn ::after {
  border-bottom: none !important;
}
.search-btn :hover {
  border-bottom: none !important;
}
.search-btn:hover:not(.Mui-disabled):before {
  border-bottom: none !important;
}
.search-btn button span svg {
  /* width: 100%; */
  display: flex;
  align-items: inherit;
  justify-content: inherit;
  color: #393939;
  border-radius: 10px;
  background: white;
  font-size: 15px;
}
.search-btn input[type="text"] {
  font-size: 12px !important;
}
.editSearch button span svg {
  /* width: 100%; */
  display: flex;
  align-items: inherit;
  justify-content: inherit;
  color: #393939;
  border-radius: 10px;
  background: #fff;
  font-size: 15px;
}
.MuiPaper-elevation1-325 ul {
  padding: 10px !important;
  border-radius: 6px !important;
}
.MuiPaper-elevation1 ul li {
  border-bottom: solid 1px rgb(57, 57, 57, 0.15) !important;
  font-size: 12px !important;
  font-family: "Biotif-Regular";
}
.imageUpload {
  cursor: pointer;
  height: 120px !important;
  width: 100%;
}
.imageInnerDiv {
  padding: 35px 20px !important;
}
.profile-video-div {
  height: 80vh;
}
.video-container {
  position: relative;
}
.profile-video {
  object-fit: cover;
  overflow: hidden;
  height: 80vh;
  width: 100%;
}

.profile-video .vjs-control-bar {
  margin-bottom: 50px;
  width: 80% !important;
  margin-left: 10%;
  background: rgba(0, 0, 0, 0.5) !important;
  border-radius: 7px;
}
.profile-video .vjs-play-progress {
  background-color: #ffa100 !important;
}
.profile-video .vjs-play-progress::before {
  color: #ffa100;
}
.top-row {
  padding: 8px 16px 0;
}
.savedKPIS {
  padding-left: 7% !important;
}
@media (min-width: 320px) and (max-width: 767px) {
  /* //CSS */
  .logo-img {
    margin-top: 10px;
    margin-left: 0;
    width: 80%;
    /* height: 7%; */
    /* margin-left: -13px; */
  }

  .sidebar .nav-link {
    font-family: Biotif-Regular;
    cursor: pointer;
    font-size: 14px;
    color: #b7b7b7;
    border-bottom: none;
    text-decoration: none;
  }
  .tab .nav-link {
    font-size: 12px;
    padding: 1em 3em 0 0;
  }

  .accText {
    font-size: 12px !important;
    font-family: Biotif-Regular;
  }
  .selectHeading {
    font-size: 11px !important;
  }
  .TeamAccordion-expandIcon-206 {
    font-size: 1.2rem !important;
  }
  .checkbox-select {
    padding: 2px 9px !important;
  }

  .tab-container {
    /* margin-top: 4em; */
    padding: 4%;
    width: 92%;
    background: transparent;
    display: block;
    z-index: 2;
    margin: 0 auto;
  }
  .detailsROw {
    width: 99% !important;
  }
  .borderLeft-div {
    padding: 0px 16px 9px 16px;
    border-left: none;
  }
  .btnRow {
    text-align: center;
  }
  td {
    font-family: "Biotif-Regular" !important;
    padding: 16px 3px !important;
    font-size: 12px !important;
  }

  .MuiInput-underline-373:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: none !important;
    pointer-events: none;
  }
  .MuiInput-underline-373:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-bottom: none !important;
    pointer-events: none;
  }
  .MuiInput-underline-373:not(.Mui-focused) {
    border-bottom: none !important;
  }
  .MuiTextField-root-353 {
    background: transparent !important;
    margin: 5px 0 16px 10px !important;
  }
  .full-input-position {
    width: 100%;
    padding: 2px 0px 2px 0px;
  }
  .efull-input-position {
    width: 100%;
    padding: 2px 0px 2px 0px !important;
  }
  .full-input-player {
    width: 100%;
    padding: 2px 0px 2px 0px;
  }
  .efull-input-player {
    width: 100%;
    padding: 2px 0px 2px 0px !important;
  }
  .full-input-player label {
    padding-left: 12px;
  }
  .efull-input-player label {
    padding-left: 12px;
  }
  .full-input-player input {
    padding-left: 12px;
  }
  .efull-input-player input {
    padding-left: 12px;
  }
  .full-input-player select {
    padding-left: 12px;
  }
  .full-input-position label {
    padding-left: 12px;
  }
  .efull-input-position label {
    padding-left: 12px;
  }
  .full-input-position select {
    padding-left: 12px;
  }
  .efull-input-position select {
    padding-left: 12px;
  }
  .full-input-bio textarea {
    padding-left: 12px;
  }
  .efull-input-bio textarea {
    padding-left: 12px;
  }
  .full-input-bio {
    width: 100%;
    padding: 5px 0px;
  }
  .efull-input-bio {
    width: 100%;
    padding: 5px 0px;
  }
  .full-input-bio label {
    padding-left: 12px;
  }
  .efull-input-bio label {
    padding-left: 12px;
  }
  .content {
    padding-bottom: 37px !important;
  }
  .borderRadius-rightM {
    border-radius: 0px 4px 4px 0px !important;
  }
  .borderRadius-leftM {
    border-radius: 4px 0px 0px 4px !important;
  }
  .statSpacing {
    background: rgb(255, 255, 255, 0.26) !important;
    margin: 15px 0 0px 0;
  }

  .addbtn-Grid {
    margin-top: 30px !important;
    text-align: center;
  }
  .ceateteam-btn {
    background: #ffc65b;
    width: 100%;
    border: none;
    /* margin-top: 10vh; */
    padding: 16px 32px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 3px;
    font-weight: bold;
    font-size: 10px;
    font-family: Biotif-Regular;
  }
  .searchEdit {
    width: 100%;
  }
}
.daterangepicker.show-calendar .drp-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.daterangepicker .drp-buttons .cancelBtn {
  position: inherit !important;
}

@media (min-width: 320px) and (max-width: 600px) {
  .sidebar {
    width: 30%;
    padding: 2% 2% 2% 6%;
  }

  .borderRadius-left {
    border-radius: 4px !important;
  }
  .borderRadius-right {
    border-radius: 4px !important;
  }
  .sesionCol {
    padding: 16px 0 0 0 !important;
  }
  .autoAlert {
    margin: 0 15px 0 0 !important;
  }
  .borderLeft-div {
    padding: 0px 16px 9px 0px;
    border-left: none;
  }
  .borderLeft-Kpis {
    padding: 0px 0px 5px;
    border-left: none;
  }
  .searchCreatlink {
    width: 80%;
  }
  .borderRadius-rightM {
    border-radius: 0px 4px 4px 0px !important;
  }
  .borderRadius-leftM {
    border-radius: 4px 0px 0px 4px !important;
  }
  .full-input-Preteam {
    padding: 2px 0px 2px 0px;
    width: 100%;
  }
  .full-input-Preteam label {
    padding-left: 12px;
  }
  .full-input-Preteam select {
    padding-left: 12px;
  }
  .full-input-team {
    padding: 2px 0px 2px 0px;
    width: 100%;
  }
  .full-input-team label {
    padding-left: 12px;
  }
  .full-input-team select {
    padding-left: 12px;
  }
  .update-btn {
    width: 100%;
    margin-top: 10px;
  }
  .remove-btn {
    width: 100%;
    margin-top: 10px;
  }
  .hide-btn {
    width: 100%;
    margin-top: 10px;
  }
  .show-btn {
    background: #707070;
    width: 100%;
    margin-top: 10px;
  }
  .profile-video-div {
    height: 27vh;
  }
  .profile-video {
    object-fit: cover;
    overflow: hidden;
    height: 27vh;
    width: 100%;
  }
}
@media (min-width: 600px) and (max-width: 767px) {
  .sidebar {
    width: 20%;
    padding: 2% 2% 2% 4%;
  }
  .borderLeft-div {
    padding: 0px 16px 9px 16px;
    border-left: solid 1px #393939;
  }
  .borderLeft-Kpis {
    padding: 0px 10px 34px;
    border-left: solid 1px #393939;
  }
  .full-input-Preteam {
    width: 100%;
  }
  .full-input-team {
    width: 100%;
  }
  .remove-btn {
    width: 94%;
  }
  .profile-video-div {
    height: 30vh;
  }
  .profile-video {
    object-fit: cover;
    overflow: hidden;
    height: 30vh;
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 959px) {
  .send-button {
    width: 53%;
  }
  .add-button {
    width: 51%;
  }
  .searchEdit {
    width: 100%;
  }
  .remove-btn {
    width: 94%;
  }
  .profile-video-div {
    height: 40vh;
  }
  .profile-video {
    object-fit: cover;
    overflow: hidden;
    height: 40vh;
    width: 100%;
  }
}
@media (min-width: 960px) {
  .edit-buttons {
    padding: 12px 13px 9px !important;
  }
}
@media (max-width: 1026px) {
  .contactportionCL {
    margin-top: -15px !important;
  }
}

@media (min-width: 1549px) {
  .imageInnerDiv {
    padding: 42px 20px !important;
  }
  .imageInnerDiv div span {
    margin-bottom: 0 !important;
  }
}
